import React from "react";
import "../styles/global.scss";
import styled from "styled-components"
import {Link} from "gatsby";

const BlogTagContainer = styled.div`
  padding: 10px 10px 7px;
  text-align: center;
  border: 2px solid #${({color}) => (color ? color : "ffffff")};
  width: fit-content;
  margin: 5px 10px 5px 0;
`

const BlogTag = ({children, color}) => {

    return (
        <BlogTagContainer color={color}>
            <p className="large">{children}</p>
        </BlogTagContainer>
    )
}
export default BlogTag
