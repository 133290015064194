import React, {useState} from "react";
import Navbar from "../components/Navbar";
import JobList from "../components/JobList";
import {graphql} from "gatsby";

import Marquee from "react-fast-marquee";
import Layout from "../components/Layout";
import MyPortableText from "../components/MyPortableText";
import BlogTag from "../components/BlogTag";
import Head from "../components/Head";

export const query = graphql`
    {
  allSanityImpressum {
    edges {
      node {
        title,
        _rawBody
      }
    }
  }
}
`


const Datenschutz = ({data}) => {

    const daten = data.allSanityImpressum.edges[0].node;

    return (
        <Layout>
            <Head title="Impressum"/>
            <Marquee gradient={false} speed={40}>
                <div className="marqueeText color-orange">
                    <span className="marqueeText__inner">IMPRESSUM</span>
                    <span className="marqueeText__inner">IMPRESSUM</span>
                    <span className="marqueeText__inner">IMPRESSUM</span>
                    <span className="marqueeText__inner">IMPRESSUM</span>
                    <span className="marqueeText__inner">IMPRESSUM</span>
                    <span className="marqueeText__inner">IMPRESSUM</span>
                    <span className="marqueeText__inner">IMPRESSUM</span>
                    <span className="marqueeText__inner">IMPRESSUM</span>
                    <span className="marqueeText__inner">IMPRESSUM</span>
                    <span className="marqueeText__inner">IMPRESSUM</span>
                    <span className="marqueeText__inner">IMPRESSUM</span>
                    <span className="marqueeText__inner">IMPRESSUM</span>
                    <span className="marqueeText__inner">IMPRESSUM</span>
                    <span className="marqueeText__inner">IMPRESSUM</span>
                </div>
            </Marquee>
            <div className="datenschutzPage blogcontent">
                <div className="blogintro__content">
                    <div className="blogintro__left">
                    </div>
                    <div className="blogintro__right">
                        <h4>{daten.title}</h4>
                    </div>
                </div>
                <div className="blog__content">
                    <MyPortableText value={daten._rawBody} />
                </div>
            </div>
        </Layout>
    )
}

export default Datenschutz;
